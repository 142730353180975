<template>
  <div>
    <v-fade-transition mode="out-in">
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-fade-transition>
    <a
      v-if="wppVisible"
      href="https://wa.me/553491855113"
    ><img
      class="wppimg"
      width="70px"
      height="70px"
      :src="require('@/assets/wpp.png')"
    ></a>
  </div>
</template>
<style>
  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
  .wppimg {
      position: fixed;
      bottom: 35px;
      right: 35px;
  }
</style>
<script>
  export default {
    name: 'App',
    metaInfo: {
      title: 'App',
      titleTemplate: '%s | Singulare Gestão Condominial | Brasil',
      htmlAttrs: { lang: 'pt-br' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    data () {
      return {
        wppVisible: false,
      }
    },
    mounted () {
      const vm = this
      setTimeout(() => { vm.wppVisible = true }, 3500)
    },
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
